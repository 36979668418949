.rotated {
  transform: rotate(-25deg);
  margin-top: 40px;

  border-radius: 35px;
}

@media (max-width: 750px) {
  .block {
    text-align: center;
  }

  .rotated {
    transform: rotate(0deg);
    margin: auto;
    padding: auto;
  }
}

.BrandValue_brand-custom-value__txD8S {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  z-index: 1;
}

.BrandValue_brand-custom-value__content__SQz4G {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 52px;
  gap: 52px;
  border-bottom: 2px solid transparent;
}

.BrandValue_brand-custom-value__txD8S a {
  display: inline-block;
  height: 28px;
}
.BrandValue_brand-custom-value__txD8S img {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.BrandValue_brand-custom-value__amount-wrapper__4ZpoY {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.BrandValue_brand-custom-value__amount-input__sa9Om,
.BrandValue_brand-custom-value__amount__AQBoY {
  font-size: 34px;
  /* font-size: 3.4rem; */
  color: #3a1a5f;
  font-weight: 600;
}

.BrandValue_brand-custom-value__txD8S a.BrandValue_disabled__zfZ4u {
  pointer-events: none;
  opacity: 0.3;
}

#denominatioNumber::-webkit-outer-spin-button,
#denominatioNumber::-webkit-inner-spin-button {
  display: none;
}
#denominatioQuantity::-webkit-outer-spin-button,
#denominatioQuantity::-webkit-inner-spin-button {
  display: none;
}

.BrandValue_brand-custom-value__amount-input__sa9Om {
  border: 0;
  min-width: 6ch;
  padding: 0;
  outline: none;
}

.BrandValue_brand-custom-value__txD8S a {
  display: inline-block;
  height: 28px;
}

.ribbon {
  width: 100px;
  height: 95px;
  z-index: 99;
  overflow: hidden;
  position: absolute;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 185px;
  padding: 8px 0;
  background-color: #017116;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 100 10px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 3px solid #2980b9;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 17px;
}

.ribbon-top-right::after {
  bottom: 17px;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
.footer-hero-text {
  text-align: justify;
}
.Toastify__toast-container {
  z-index: 200000;
}
.pl5 {
  padding-left: 3rem !important;
}
.icon_box_style4 {
  min-height: 300px;
}
.card-text {
  margin-bottom: 0;
  bottom: 40%;
  position: absolute;
  color: #fff;
  left: 15%;
  right: 15%;
  font-weight: bold;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
}

@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .mobile {
    display: none;
  }
  .banner_section:not(.full_screen),
  .banner_section:not(.full_screen) .carousel-item,
  .banner_section:not(.full_screen) .banner_content_wrap,
  .banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: auto !important
  }
}

@media screen and (max-width: 575px) {
 
  .banner_section:not(.full_screen),
  .banner_section:not(.full_screen) .carousel-item,
  .banner_section:not(.full_screen) .banner_content_wrap,
  .banner_section:not(.full_screen) .banner_content_wrap .carousel-item {
    height: auto !important
  }
}
.sc-gEvEer .ffXrxx {
  z-index: 200000;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is on top of other elements */
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.input-bottom-line {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #424242; /* Set the color and style you desire */
  border-radius: 0;
  box-shadow: none;
  /* Additional styles if needed */
}
.input-bottom-line:hover,
.input-bottom-line:focus {
  box-shadow: none; /* Remove box shadow on hover and focus */
}

input#minus-btn {
  background-color: #FF324D;
  color: white;
  font-weight: bolder;
 
}

input#plus-btn {
  background-color: #FF324D;
  color: white;
  font-weight: bolder;
 
}